import React, { useContext, useState } from 'react';
import TowerCommands from '../../../common/Peripherals/Tower/TowerCommands';
import { Card } from 'react-bootstrap';
import styles from './PeripheralCommunication.module.css';
import PeripheralIdentifier from '../../../common/Peripherals/PeripheralIdentifier';
import LightingCommands from '../../../common/Peripherals/Lighting/LightingCommands';
import ReceptionCommands from '../../../common/Peripherals/Reception/ReceptionCommands';
import DeckCommands from '../../../common/Peripherals/Deck/DeckCommands';
import GantryCommands from '../../../common/Peripherals/Gantry/GantryCommands';
import AdditivesCommands from '../../../common/Peripherals/Additives/AdditivesCommands';
import AuthGuard from '../../../components/auth/AuthGuard';
import UserRole from '../../../common/UserRole';
import { useAppSelector } from '../../../hooks/storeHooks';
import PeripheralCommandInputs from './PeripheralCommandInputs/PeripheralCommandInputs';
import { KioskSettingsContext } from '../KioskEdit/Settings/KioskSettingsContext';
import CommandCode from '../../../common/Peripherals/FirmwareCommunication/Messaging/CommandCode';
import GantryJog from './PeripheralControls/GantryControls/GantryControls';
import PeripheralControls from './PeripheralControls/PeripheralControls';
import KioskEditStrings from '../KioskEdit/KioskEditStrings';
import KioskType from '../../../common/Kiosk/KioskType';
import MonitorCommands from '../../../common/Peripherals/Monitor/MonitorCommands';

const PeripheralCommunication: React.FC = () => {
  const [error, setError] = useState<string>('');
  const user = useAppSelector((store) => store.auth?.user);
  const kioskSettings = useContext(KioskSettingsContext);
  const deviceStatus = kioskSettings?.deviceStatus;
  const kioskId = kioskSettings?.state?.kioskId;
  const kioskType = kioskSettings?.state.settings?.installInfo?.kioskType;

  return (
    <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user.role}>
      <Card style={{ padding: '2%' }}>
        <Card.Body>
          <div className={styles.PeripheralCommunication}>
            {error !== '' && <div className={styles.errorMessage}>{error}</div>}
            {kioskId && (
              <>
                <h4>{KioskEditStrings.PeripheralCommandsHeading}</h4>
                <table style={{ padding: '20px' }}>
                  <tbody>
                    <tr>
                      <th align='left'>Peripheral</th>
                      <th align='left'>Command Code</th>
                      <th align='left'>Parameters</th>
                      <th align='left'>&nbsp;</th>
                      <th align='left'>Response Time</th>
                      <th align='left'>Response</th>
                    </tr>

                    {deviceStatus && kioskId && (
                      <>
                        {kioskType === KioskType.Duo && (
                          <PeripheralCommandInputs
                            peripheralIdentifier={PeripheralIdentifier.Tower}
                            commands={TowerCommands}
                            onError={(errorMessage) => setError(errorMessage)}
                            status={deviceStatus}
                            kioskId={kioskId}
                            kioskType={kioskType}
                          />
                        )}
                        <PeripheralCommandInputs
                          peripheralIdentifier={PeripheralIdentifier.Lighting}
                          commands={LightingCommands}
                          onError={(errorMessage) => setError(errorMessage)}
                          status={deviceStatus}
                          kioskId={kioskId}
                          kioskType={kioskType}
                        />
                        {kioskType === KioskType.Duo && (
                          <PeripheralCommandInputs
                            peripheralIdentifier={PeripheralIdentifier.Reception}
                            commands={ReceptionCommands}
                            onError={(errorMessage) => setError(errorMessage)}
                            status={deviceStatus}
                            kioskId={kioskId}
                            kioskType={kioskType}
                          />
                        )}
                        {kioskType === KioskType.Duo && (
                          <PeripheralCommandInputs
                            peripheralIdentifier={PeripheralIdentifier.Deck}
                            commands={DeckCommands}
                            onError={(errorMessage) => setError(errorMessage)}
                            status={deviceStatus}
                            kioskId={kioskId}
                            kioskType={kioskType}
                          />
                        )}
                        {kioskType === KioskType.Refill && (
                          <PeripheralCommandInputs
                            peripheralIdentifier={PeripheralIdentifier.Monitor}
                            commands={MonitorCommands}
                            onError={(errorMessage) => setError(errorMessage)}
                            status={deviceStatus}
                            kioskId={kioskId}
                            kioskType={kioskType}
                          />
                        )}
                        <PeripheralCommandInputs
                          peripheralIdentifier={PeripheralIdentifier.Additives}
                          commands={AdditivesCommands}
                          onError={(errorMessage) => setError(errorMessage)}
                          status={deviceStatus}
                          kioskId={kioskId}
                          kioskType={kioskType}
                        />
                        {kioskType === KioskType.Duo && (
                          <PeripheralCommandInputs
                            peripheralIdentifier={PeripheralIdentifier.Gantry}
                            commands={GantryCommands}
                            onError={(errorMessage) => setError(errorMessage)}
                            status={deviceStatus}
                            kioskId={kioskId}
                            inputDialog={new Map([[CommandCode.Motion, () => GantryJog]])}
                            kioskType={kioskType}
                          />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <PeripheralControls kioskId={kioskId} kioskType={kioskType} />
              </>
            )}
          </div>
        </Card.Body>
      </Card>
    </AuthGuard>
  );
};

export default PeripheralCommunication;
